import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const StyledContainer = styled("div")`
  flex-basis: 50%;
  flex-grow: 1;
  text-align: center;
  width: 50vw;
  height: 600px;
  @media (max-width: 768px) {
    width: 100vw;
    height: 300px;
  }
`

const StyledBackgroundSection = ({ children, data }) => {
  return (
    <BackgroundImage fluid={data.image.childImageSharp.fluid}>
      <StyledContainer component="section">{children}</StyledContainer>
    </BackgroundImage>
  )
}

export const BGImage = styled(StyledBackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
`
export default BGImage
