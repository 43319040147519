import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { NavLink } from "./NavLink"

const StyledNavLink = styled(NavLink)`
  margin-right: 32px;
 
`

export const NavBrand = props => {
  const { children, ...rest } = props
  return <StyledNavLink {...rest}>{children}</StyledNavLink>
}

NavBrand.propTypes = {
  children: PropTypes.node,
}
