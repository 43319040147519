import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/core/styles"
import gouldTheme from "../theme"
import GlobalStyle from "./GlobalStyle"
import Header from "./header"
import { Footer} from '../components/organisms'

const Layout = ({route, children}) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <CssBaseline />
      <GlobalStyle/>
      <ThemeProvider theme={gouldTheme}>
      <Header siteTitle={data.site.siteMetadata.title} route={route}/>
        <main>{children}</main>
        <Footer/>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
