import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const ServiceImg = ({ altText, imgIndex, height }) => {
  const data = useStaticQuery(graphql`
    query {
      img0: file(relativePath: { eq: "services/asphalt-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img1: file(relativePath: { eq: "services/concrete-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "services/garden-walkway-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "services/handicap-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "aerial-view-of-parking-area.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(relativePath: { eq: "services/waterproofing-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img6: file(relativePath: { eq: "services/light-bulb-p-800.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img7: file(relativePath: { eq: "services/roofing2-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img8: file(relativePath: { eq: "services/jackhammer-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img9: file(relativePath: { eq: "services/construction-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img10: file(relativePath: { eq: "services/kitchen-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const Img1 = () => (
    <Img fluid={data.img0.childImageSharp.fluid} style={{ height: `${height}px` }}  alt={altText}/>
  )
  const Img2 = () => (
    <Img fluid={data.img1.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img3 = () => (
    <Img fluid={data.img2.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img4 = () => (
    <Img fluid={data.img3.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img5 = () => (
    <Img fluid={data.img4.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img6 = () => (
    <Img fluid={data.img5.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img7 = () => (
    <Img fluid={data.img6.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img8 = () => (
    <Img fluid={data.img7.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img9 = () => (
    <Img fluid={data.img8.childImageSharp.fluid} style={{ height: `${height}px` }} alt={altText}/>
  )
  const Img10 = () => (
    <Img fluid={data.img9.childImageSharp.fluid} style={{ height: `${height}px`}} alt={altText}/>
  )
  const Img11 = () => (
    <Img fluid={data.img10.childImageSharp.fluid} style={{ height: `${height}px`}} alt={altText}/>
  )
  const images = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
  ]

  return <>{images[imgIndex]()}</>
}

export default ServiceImg
