import React from "react"
import {
  Container,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { SectionHeading } from "../atoms"

const StyledContainer = styled(Container)`
  margin-bottom: 64px;
`
const StyledCard = styled(Card)`
  text-align: left;
  flex-basis: 23%;
  box-shadow: none;
  background-color: transparent;
  @media (max-width: 991px) {
    flex-basis: 48%;
  }
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const CenteredRow = styled(StyledRow)`
  justify-content: center;
`

const StyledContent = styled(CardContent)`
  padding-left: 0;
`

export const Expertise = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "commercial-p-1080.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "warehouse.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "aerial-view-of-parking-area.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "luxury-home-p-1600.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Img1 = () => (
    <Img fluid={data.img1.childImageSharp.fluid} style={{ height: "200px" }} />
  )
  const Img2 = () => (
    <Img fluid={data.img2.childImageSharp.fluid} style={{ height: "200px" }} />
  )
  const Img3 = () => (
    <Img fluid={data.img3.childImageSharp.fluid} style={{ height: "200px" }} />
  )
  const Img4 = () => (
    <Img fluid={data.img4.childImageSharp.fluid} style={{ height: "200px" }} />
  )

  return (
    <StyledContainer maxWidth="xl">
      <CenteredRow>
        <SectionHeading
          textAlign="center"
          tagline="WHAT WERE GOOD AT"
          heading="Expertise"
          subheading="GEI is an industry leader who serves as a proud partner from inception to delivery."
        />
      </CenteredRow>
      <StyledRow>
        <StyledCard>
          <Img1 />
          <StyledContent>
            <Typography variant="h6" gutterBottom>
              Commercial Engineering
            </Typography>
            <Typography variant="body2">
              Grading, paving, construction, and hardscape, all professionally
              installed with the highest quality products to create outstanding
              finished products.
            </Typography>
          </StyledContent>
        </StyledCard>
        <StyledCard>
          <Img2 />
          <StyledContent>
            <Typography variant="h6" gutterBottom>
              Industrial Surfacing
            </Typography>
            <Typography variant="body2">
              Industry leader in product selection and application. Resilient
              concrete warehouse floors installed and repaired with protective
              coatings.  From crack injection to complete parking structures,
              trust GEI for proven, quality results.
            </Typography>
          </StyledContent>
        </StyledCard>
        <StyledCard>
          <Img3/>
          <StyledContent>
            <Typography variant="h6" gutterBottom>
              Parking Lots & Roads
            </Typography>
            <Typography variant="body2">
              Asphalt or concrete paving, drainage systems, and ADA handicapped
              compliance, GEI provides precision solutions to every detail.
              Security fencing, automatic gates and traffic devices, GEI can
              literally take it from the ground-up.
            </Typography>
          </StyledContent>
        </StyledCard>
        <StyledCard>
          <Img4 />
          <StyledContent>
            <Typography variant="h6" gutterBottom>
              Residential Construction
            </Typography>
            <Typography variant="body2">
              GEI can help you create an elegant home environment. Paving,
              masonry, outdoor kitchens, spas and decks are just a few of the
              ways GEI can help you reach your dreams.
            </Typography>
          </StyledContent>
        </StyledCard>
      </StyledRow>
    </StyledContainer>
  )
}

export default Expertise
