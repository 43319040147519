import React from "react"
import styled from "styled-components"
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import { BGImage } from "../atoms"
import { graphql, useStaticQuery } from "gatsby"
import { SectionHeading } from "../atoms"
import CheckBoxIcon from "@material-ui/icons/CheckBox"

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 64px;
  background-color: #e0e0e0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`
const StyledContent = styled.div`
  width: 100%;
  padding: 16px 16px 16px 32px;
  text-align: left;
  @media (max-width: 768px) {
    width: 100vw;
    padding-left: 16px;
  }
`
const StyledCheckBox = styled(CheckBoxIcon)`
  color: #DAA50E;
`
const StyledButton = styled(Button)`
  margin-top: 16px;
  //background-color: #ffc30e;
  //color: black;
  &:hover {
    background-color: black;
    color: white;
  }
`

export const About = () => {
  const bgImage = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "building-plan-p-1600.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledRow id="about">
      <BGImage data={bgImage} />
      <StyledContent>
        <SectionHeading
          left={true}
          tagline="who we are"
          heading="Welcome to GEI"
          subheading="Since 1975, GEI has earned hundreds of satisfied/repeat clients while paving over a million square feet. Let GEI serve you in your next paving project."
        />
        <List>
          <ListItem>
            <ListItemIcon color="primary">
              <StyledCheckBox />
            </ListItemIcon>
            <ListItemText primary="Full Service Contractor" secondary={null} />
          </ListItem>
          <ListItem>
            <ListItemIcon color="primary">
              <StyledCheckBox />
            </ListItemIcon>
            <ListItemText
              primary="Highest workplace safety rating possible."
              secondary={null}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon color="primary">
              <StyledCheckBox />
            </ListItemIcon>
            <ListItemText
              primary="LEED “Green” Accredited Professional Credential"
              secondary={null}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon color="primary">
              <StyledCheckBox />
            </ListItemIcon>
            <ListItemText
              primary="Environmentally conscience  aka “green” leader"
              secondary={null}
            />
          </ListItem>
        </List>
        <StyledButton variant="contained" size="large" color="primary">
          Get In Touch
        </StyledButton>
      </StyledContent>
    </StyledRow>
  )
}

About.propTypes = {}

export default About
