import React from "react"
import { IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import styled from "styled-components"

const Burger = styled(IconButton)`
  display: flex;
  color: white;
`

export const Hamburger = () => {
  return (
    <Burger aria-haspopup="true" aria-controls="simple-menu" edge="start" aria-label="menu">
      <MenuIcon />
    </Burger>
  )
}
