import React from "react"
import PropTypes from "prop-types"
import { Button, Typography } from "@material-ui/core"
import { Link } from "gatsby"

import styled from "styled-components"

const StyledNavLink = styled(Link)`
  position: static;
  display: flex;
  height: 48px;
  min-width: 0;
  padding: 8px 18px;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;
  &:hover {
  border-bottom-color: #ffc30e;
  }
`
export const NavLink = props => {
  const { as, to, children, ...rest } = props
  return (
    <StyledNavLink to={to}>
      {as === "button" ? (
        <Button {...rest}>{children}</Button>
      ) : (
        <Typography {...rest}>
          {children}
        </Typography>
      )}
    </StyledNavLink>
  )
}

NavLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,

}
