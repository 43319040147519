import React from "react"
import PropTypes from "prop-types"
import { AppBar, Toolbar } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import styled from "styled-components"
import TopBar from "./TopBar"
import { useWindowScroll } from "react-use"

const StyledNav = styled("div")`
  flex-grow: 1;
  flex-wrap: wrap;
`

const StyledAppBar = styled(AppBar)`
  background-color: ${props =>
    props.bgcolor === "true" ? "#04131A" : `rgba(0,0,0,.20)`};
  color: ${props => (props.bgcolor === "true" ? "white" : "white")};
`
const StyledToolbar = styled(Toolbar)`
  max-width: 1440px;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  padding: 8px 16px;
`

export const NavBar = props => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const { x, y } = useWindowScroll()
  const { children, route, ...rest } = props

  return (
    <StyledNav>
      {route === "/" ? (
        <StyledAppBar bgcolor={y > 100 ? "true" : "white"} {...rest}>
          {y < 100 && matches ? <TopBar /> : null}
          <StyledToolbar>{children}</StyledToolbar>
        </StyledAppBar>
      ) : (
        <StyledAppBar style={{backgroundColor: '#04131A'}}{...rest}>
          <StyledToolbar>{children}</StyledToolbar>
        </StyledAppBar>
      )}
    </StyledNav>
  )
}

NavBar.propTypes = {
  children: PropTypes.node,
}
