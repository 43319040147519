import React from "react"
import { Icon, IconButton, Link } from "@material-ui/core"
import styled from "styled-components"
import { loadCSS } from "fg-loadcss"

export const FontAwesomeNavLink = ({ href, ...props }) => {
  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#font-awesome-css")
    )
  }, [])
  return (
    <Link href={href}>
      <IconButton {...props} />
    </Link>
  )
}

/*
Used when you need an icon for iconography but not navigation
Todo: convert loadCSS to a hook that can be placed at the root of the application
 */

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  color: black;
`
export const FontAwesomeIcon = ({ href, ...props }) => {
  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#font-awesome-css")
    )
  }, [])
  return <StyledIcon {...props} />
}
