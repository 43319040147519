import React, { useEffect } from "react"
import {
  Container,
  Card,
  CardContent,
  Typography,
  Icon,
} from "@material-ui/core"
import { SectionHeading } from "../atoms"
import styled from "styled-components"
import { loadCSS } from "fg-loadcss"

const StyledContainer = styled(Container)`
  margin-bottom: 64px;
`
const StyledCard = styled(Card)`
  max-width: 360px;
  box-shadow: none;
  background-color: transparent;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const CenteredRow = styled(StyledRow)`
  justify-content: center;
`
const StyledIcon = styled(Icon)`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: baseline;
  height: 116px;
  width: 116px;
  font-size: 56px;
  border-radius: 50%;
  padding: 32px 24px 24px 24px;
  margin-bottom: 16px;
  color: #ffc30e;
  background-color: #e9e9e9;
  &:hover {
    background-color: #ffc30e;
    color: #e9e9e9;
  }
`

export const CorePrinciples = () => {
  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#font-awesome-css")
    )
  }, [])
  return (
    <StyledContainer maxWidth="xl">
      <CenteredRow>
        <SectionHeading
          textAlign="center"
          tagline="What we believe in"
          heading="Core Principles"
          subheading="Gould Engineering Inc. (GEI) was founded in 1975 on three business
          principals"
        />
      </CenteredRow>
      <StyledRow>
        <StyledCard>
          <CardContent>
            <StyledIcon className="fas fa-award" />
            <Typography variant="h4" gutterBottom>
              Best Quality
            </Typography>
            <Typography variant="body2">
              Providing the highest quality finished product, using the state of
              the art materials and installation techniques backed up by the
              best warranties in the industry.
            </Typography>
          </CardContent>
        </StyledCard>
        <StyledCard>
          <CardContent>
            <StyledIcon className="fas fa-handshake" />
            <Typography variant="h4" gutterBottom>
              Integrity
            </Typography>
            <Typography variant="body2">
              Old fashioned American business practices, such as honesty,
              integrity, and pride in workmanship have propelled GEI to the top
              our field.
            </Typography>
          </CardContent>
        </StyledCard>
        <StyledCard>
          <CardContent>
            <StyledIcon className="fas fa-calendar-check" />
            <Typography variant="h4" gutterBottom>
              Timeliness
            </Typography>
            <Typography variant="body2">
              GEI provides a full service paving company from design, project
              management, and on time job completion.
            </Typography>
          </CardContent>
        </StyledCard>
      </StyledRow>
    </StyledContainer>
  )
}

CorePrinciples.propTypes = {}

export default CorePrinciples
