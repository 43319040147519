import React from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles';

export const StyledHeading = styled.section`
  text-align: ${props => props.textAlign || 'left'};
  max-width: 400px;
  margin: 16px 0 32px 0;
   @media (max-width: 768px) {
    padding: 16px 16px;
    
  }
`

const useStyles = makeStyles({
  overline: {
    marginBottom: 16
  },
  heading: {
    marginBottom: 16,
  },
  subheading: {
    marginBottom: 8,
  }

});


export const SectionHeading = ({tagline, heading, subheading, textAlign}) => {
  const classes = useStyles();
  return (
      <StyledHeading textAlign={textAlign}>
        <Typography variant="overline" className={classes.overline}>
          {tagline}
        </Typography>
        <Typography variant="h4" gutterBottom className={classes.heading}>{heading}</Typography>
        <Typography variant="subtitle1" className={classes.subheading}>
         {subheading}
        </Typography>
      </StyledHeading>
  )
}

SectionHeading.propTypes = {
  tagline: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

export default SectionHeading
