import React from "react"
import PropTypes from "prop-types"
import { Card } from "@material-ui/core"
import styled from "styled-components"
import { ServiceImg } from "../atoms"

const StyledCard = styled(Card)`
  position: relative;
  text-align: left;
  box-shadow: none;
  background-color: transparent;
  margin: 16px 8px 32px 8px;
  width: auto;
`

export const ServicesDetailCard = ({
  ImgTitle,
  children,
  imgIndex,
  height,
}) => {
  return (
    <StyledCard>
      <ServiceImg alt={ImgTitle} imgIndex={imgIndex} height={height} />
      {children}
    </StyledCard>
  )
}

ServicesDetailCard.propTypes = {
  Img: PropTypes.func,
  ImgTitle: PropTypes.string,
  label: PropTypes.string,
  imgName: PropTypes.string,
}

export default ServicesDetailCard
