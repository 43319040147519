import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const StyledContainer = styled('div')`
  flex-basis: 100%;
  flex-grow: 1;
  width: 100vw;
  padding: 48px 0 0 0;
  background-color: rgba(0,0,0,.87);
  height: 100%;
  color: white;
    @media (max-width: 768px) {
    width: 100vw;
  }
`

const StyledBackgroundSection = ({ children, data }) => {
  return (
    <BackgroundImage
      fluid={data.image.childImageSharp.fluid}
    >
      <StyledContainer component='section'>
        {children}
      </StyledContainer>
    </BackgroundImage>
  )
}

export const FooterBgImg = styled(StyledBackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
  background-color: rgba(0,0,0,.2);
`
export default FooterBgImg
