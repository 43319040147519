import React from "react"
import PropTypes from "prop-types"
import { Card, CardContent, Typography } from "@material-ui/core"
import styled from "styled-components"
import { Link } from "gatsby"
import {ServiceImg} from "../atoms"

const StyledCard = styled(Card)`
  position: relative;
  text-align: left;
  box-shadow: none;
  background-color: transparent;
  margin: 8px 8px;
  width: auto;
  `

const StyledContent = styled(CardContent)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  color: transparent;
  text-transform: uppercase;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
`

export const ServicesCard = ({ label, children, imgIndex, height, altText }) => {
  return (
    <StyledCard>
      <ServiceImg imgIndex={imgIndex} height={height} altText={altText}/>
      <Link to="/services">
        <StyledContent>
          <Typography variant="h6" gutterBottom>
            {label}
          </Typography>
        </StyledContent>
      </Link>
      {children}
    </StyledCard>
  )
}

ServicesCard.propTypes = {
  Img: PropTypes.func,
  ImgTitle: PropTypes.string,
  label: PropTypes.string,
}

export default ServicesCard
