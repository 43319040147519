import React from "react"
import { Icon, Link } from "@material-ui/core"
import styled from "styled-components"

const StyledLink = styled(Link)`
  padding: 0 8px;
`

const SocialIcons = styled(Icon)`
  font-size: 15px;
  vertical-align: middle;
  color: black;
`

export const SocialIconLink = ({ href, ...props }) => {
  return (
    <StyledLink href={href}>
      <SocialIcons {...props} />
    </StyledLink>
  )
}

export default SocialIconLink
