import React from "react"
import { Container } from "@material-ui/core"
import { SectionHeading } from "../atoms"
import styled from "styled-components"
import { ServicesCard } from "../molecules"
import { GridContainer, GridItem } from "../Grid"
import servicesData from "../../content/services"

const StyledContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 64px; 
`

export const Services = () => {
  return (
    <div style={{backgroundColor: "#f4f4f4"}}>
      <StyledContainer maxWidth="xl">
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "16px" }}>
            <SectionHeading
              textAlign="center"
              tagline="HOW CAN WE SERVE YOU?"
              heading="Services"
              subheading="Are you interested in finding out how we can make your project a success?"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          {servicesData.services.map((service, index) => {
            return (
              <GridItem xs={12} sm={6} md={4} lg={3} key={`${service}${index}`}>
                <ServicesCard
                  altText={service.altText}
                  imgIndex={index}
                  height={200}
                  label={service.title}
                />
              </GridItem>
            )
          })}
        </GridContainer>
      </StyledContainer>
    </div>
  )
}

export default Services
