import React from "react"
import { Container, GridList, GridListTile } from "@material-ui/core"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { SectionHeading } from "../atoms"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const StyledContainer = styled(Container)`
padding: 64px 0 92px 0;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const CenteredRow = styled(StyledRow)`
  justify-content: center;
`

const StyledGridList = styled(GridList)`
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  transform: translateZ(0);
`

export const Clients = () => {
  const matches = useMediaQuery("(min-width:768px)")
  const phone = useMediaQuery("(min-width:479px)")
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "clients/chevronLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "clients/cvsLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "clients/hiltonLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "clients/hufshutLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(relativePath: { eq: "clients/remaxLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Img1 = () => (
    <Img
      fluid={data.img1.childImageSharp.fluid}
      style={{ height: "140px", width: "128px" }}
    />
  )
  const Img2 = () => (
    <Img
      fluid={data.img2.childImageSharp.fluid}
      style={{ height: "140px", width: "128px" }}
    />
  )
  const Img3 = () => (
    <Img
      fluid={data.img3.childImageSharp.fluid}
      style={{ height: "140px", width: "128px" }}
    />
  )

  const Img4 = () => (
    <Img
      fluid={data.img4.childImageSharp.fluid}
      style={{ height: "140px", width: "128px" }}
    />
  )
  const Img5 = () => (
    <Img
      fluid={data.img5.childImageSharp.fluid}
      style={{ height: "140px", width: "128px" }}
    />
  )

  return (
    <StyledContainer maxWidth="xl">
      <CenteredRow>
        <SectionHeading
          textAlign="center"
          tagline="Keeping our clients happy is our goal!"
          heading="Our Clients"
          subheading="With hundreds of clients across many industries we handle both small and large projects."
        />
      </CenteredRow>
      <StyledGridList cellHeight={140} cols={matches ? 5 : phone ? 3 : 2}>
        <GridListTile>
          <Img1 />
        </GridListTile>
        <GridListTile>
          <Img2 />
        </GridListTile>
        <GridListTile>
          <Img3 />
        </GridListTile>
        <GridListTile>
          <Img4 />
        </GridListTile>
        <GridListTile>
          <Img5 />
        </GridListTile>
      </StyledGridList>
    </StyledContainer>
  )
}

export default Clients
