import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledNavMenu = styled("nav")`
  display: flex;
  flex-grow: 1;
  height: 70px;
  align-items: center;
  justify-content: ${props => props.align || "flex-start"};
`
export const NavMenu = props => {
  const { children, align } = props
  return (
    <StyledNavMenu role="navigation" align={align}>
      {children}
    </StyledNavMenu>
  )
}

NavMenu.propTypes = {
  children: PropTypes.node,
}
