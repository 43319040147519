import React from "react";
import PropTypes from "prop-types";
import { Typography, useTheme, Menu, MenuItem } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavBar, NavLink, NavMenu, SiteBrand } from "./Navigation";
import styled from "styled-components";
import { Link } from "gatsby";

const Burger = styled(IconButton)`
  display: flex;
  color: white;
`;

const Header = ({ route }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NavBar route={route}>
        <SiteBrand />
        <Typography variant="h6" as="h1">
          Engineering Inc.
        </Typography>
        <NavMenu align="flex-end">
          {matches ? (
            <>
              <NavLink to="#about" variant="caption">
                About
              </NavLink>
              <NavLink to="/services" variant="caption">
                Services
              </NavLink>
              <NavLink to="/clients" variant="caption">
                Clients
              </NavLink>
              <NavLink to="/#contact" variant="caption">
                Contact
              </NavLink>
            </>
          ) : (
            <>
              <Burger
                aria-haspopup="true"
                aria-controls="simple-menu"
                edge="start"
                aria-label="menu"
                onClick={handleClick}
              >
                <MenuIcon />
              </Burger>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Link
                  to="#about"
                  variant="caption"
                  style={{ color: "#1e1e1e", textDecoration: "none" }}
                >
                  <MenuItem onClick={handleClose}>About</MenuItem>
                </Link>
                <Link
                  to="/services"
                  variant="caption"
                  style={{ color: "#1e1e1e", textDecoration: "none" }}
                >
                  <MenuItem onClick={handleClose}>Services</MenuItem>
                </Link>
                <Link
                  to="/clients"
                  variant="caption"
                  style={{ color: "#1e1e1e", textDecoration: "none" }}
                >
                  <MenuItem onClick={handleClose}>Clients</MenuItem>
                </Link>
                <Link
                  to="#contact"
                  variant="caption"
                  style={{ color: "#1e1e1e", textDecoration: "none" }}
                >
                  <MenuItem onClick={handleClose}>Contact</MenuItem>
                </Link>
              </Menu>
            </>
          )}
        </NavMenu>
      </NavBar>
    </>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
