import React from "react"
import styled from "styled-components"
import Phone from "@material-ui/icons/Phone"
import Fax from "@material-ui/icons/SettingsPhone"
import Email from "@material-ui/icons/Email"
import { Typography,Collapse } from "@material-ui/core"
import { Toolbar } from "@material-ui/core"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

const StyledContactInfo = styled.div`
  display: flex;
  margin-right: 16px;
  align-items: center;
  & svg {
    font-size: 18px;
  }
  & span {
    margin-left: 16px;
    padding-right: 16px;
  }
`

const StyledIcon = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
`

const StyledToolbar = styled(Toolbar)`
  max-width: 1440px;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
`

const StyledEmailInfo = styled(StyledContactInfo)`
  margin-left: auto;
`

const StyledDivider = styled("hr")`
  margin: 0;
  border-color: rgba(255, 255, 255, 0.3);
  max-width: 1920px;
  flex-basis: 100%;
`

const ContactInfo = ({ children }) => (
  <Typography as="span" variant="caption">
    {children}
  </Typography>
)

export const TopBar = () => {
  const trigger = useScrollTrigger()
  return (
      <Collapse in={!trigger}>
        <StyledToolbar>
          <StyledContactInfo>
            <StyledIcon>
              <Phone size={16} />
            </StyledIcon>
            <ContactInfo>(310) 831-4343</ContactInfo>|
          </StyledContactInfo>
          <StyledContactInfo>
            <StyledIcon>
              <Fax />
            </StyledIcon>
            <ContactInfo>1 - (877) GOULD90</ContactInfo>
          </StyledContactInfo>
          <StyledEmailInfo>
            <Email />
            <ContactInfo>info@gouldengineeringinc.com</ContactInfo>
          </StyledEmailInfo>
          <StyledDivider />
        </StyledToolbar>
      </Collapse>

  )
}

export default TopBar
